/* POPUP BOX */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup-content {
  border-radius: 10px;
  position: relative;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
}

.search-icon {
  width: 25px;
  height: 25px;
}
.request-details {
  width: 60%;
  background: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.request-user-details {
  width: 100%;
  display: flex;
}
.request-value {
  width: 55%;
  margin: 0px;
  padding-left: 5px;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
}

.request-key {
  width: 45%;
  text-align: start;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.16px;
  color: #000000;
  margin: 0px;
  padding-left: 8px;
}
.tech-user-details {
  font-family: Roboto Flex;
  font-size: 11px;
  font-weight: 700;
  line-height: 23.44px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
  padding: 0px 3px;
  margin: 2px;
}
.close-icon-block {
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.popup-box {
  width: 100%;
  display: flex;
}
.tech-details {
  width: 40%;
  background: #0e427a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 0px;
}
.popup-heading-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-logo-block {
  width: 20%;
  height: 20%;
}
.popup-logo-heading {
  font-family: Roboto Flex;
  font-size: 22px;
  font-weight: 700;
  line-height: 35.16px;
  text-align: center;
  color: #ffffff;
}
.popup-profile-block {
  width: 100%;
  display: flex;
  justify-content: center;
}
.popup-profile {
  width: 150px;
  height: 150px;
}
.confirmation-popup {
  position: relative;
  padding: 5px 0px 0px 0px;
  width: 80%;
  max-width: 570px;
  height: auto;
  background-color: white;
  border-radius: 10px;
}
.popUpBackGround {
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0px 0px;
}
.popupBoxBack {
  width: 100%;
  height: 23vh;
  background-image: url("/public/assets/images/billingsystem.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  display: flex;
  justify-content: end;
  margin: 0px;
  padding: 0px;
}
.shareIcon {
  width: 32px;
  height: 32px;
  display: flex;
  /* gap: 10px; */
  /* padding: 10px; */
  cursor: pointer;
}
.downloadImageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.popUpContent {
  font-family: Roboto Flex;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #000000;
}
.popupDeleteUser {
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #000000;
}
.popUpContentPara {
  font-family: Roboto Flex;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.confirmation-popup p,
.success-popup p {
  margin-bottom: 15px;
  font-family: Roboto Flex;
  font-size: 20px;
  font-weight: 550 !important;
  text-align: center;
  padding: 20px;
  color: #000000;
}

.confirmation-popup button {
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  background: #4959aace !important;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: white;
  margin: 15px 0px;
}
.confirmation-popup button:hover {
  background: #4959aa !important;
}
.success-popup button {
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  background: #4959aa !important;
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  color: white;
}
/* POPUP END */
.img_blocks {
  display: flex;
}
.popup-room-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 24.4px;
  color: #4e73de;
  padding: 10px 0px;
}
.popup-label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  font-family: Poppins;
}
.popup-input-field {
  width: 100%;
  padding: 8px 5px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: left;
  border-radius: 5px;
  outline: none;
}
.--new-room-type {
  border-bottom: 1px solid black;
}
.popup-room-content {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
}
.new-room-popup-content {
  border-radius: 10px;
  position: relative;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  height: 65vh;
  overflow: auto;
  scrollbar-width: thin;
  /* background-color: #ffffff; */
  background: #dadada;
}
.new-room-sucess-popup {
  border-radius: 10px;
  position: relative;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
}
.new-room-submit-btn {
  border-radius: 5px;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #ffff;
  border: none;
  outline: none;
  padding: 12px 0px;
  background: #243975;
  cursor: pointer;
  width: 28%;
  height: 42px;
}

.new-room-submit-btn:hover {
  background: #4e72deee;
}

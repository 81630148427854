/* MEDIA QUERY */

@media (min-width: 250px) and (max-width: 768px) {
  .rentalFormBlock {
    width: 100% !important;
  }
  .shareIcon {
    width: 25px;
    height: 25px;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  .billingDateField,
  .monthDatePicker,
  .otherAmount {
    font-size: 13px !important;
  }
  .BillingAddInputLabel {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .bookingDateEditInput,
  .bookingEditInput {
    font-size: 13px !important;
  }
  .view-bill-head {
    font-size: 12px !important;
    line-height: 14px !important;
  }
  .view-bill-status,
  .view-bill-search,
  .overview-download-block,
  .overview-btn,
  .view-hostel-status,
  .new-room-btn {
    font-size: 10px !important;
    line-height: normal !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 250px) and (max-width: 450px) {
  .view-register-btn {
    font-size: 12px !important;
    line-height: normal !important;
    /* width: 45% !important; */
  }
  .billing-logo {
    /* width: 100px !important; */
    height: 80px !important;
  }
  .bill_home_pgg{
    flex-direction: column;
      }
  .company_name_shopename{
    font-size:1rem !important;
  }
  .card-content-para {
    font-size: 10px !important;
  }
  .card-content-ac-type {
    font-size: 12px !important;
  }
  .sidebar-head {
    font-size: 12px !important;
  }
  .sidebar-image {
    width: 30px !important;
    height: 30px !important;
    border-radius: 2px !important;
    opacity: 1 !important;
  }
  .nav-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .new-room-popup-content {
    height: 80vh !important;
  }
  .register-title {
    font-size: 18px !important;
    line-height: normal !important;
  }
  .view-bill-title{
    font-size: 15px !important;
    line-height: normal !important;
  }
  .daterange-viewbill{
    width: 30% !important;
  }

  .billingInputFieldBlock,
  .billingInputLabelBlock {
    width: 100% !important;
  }
  .billingSubmitButton {
    display: flex;
    justify-content: center !important;
  }
  .new-bill-from-to-month,
  .billingDateField,
  .fromUnitsBlock,
  .toUnitsBlock,
  .new-bill-heading-block,
  .new-bill-search-block {
    width: 100% !important;
  }
  .new-bill-units-block {
    flex-direction: column !important;
    width: 100% !important;
    align-items: baseline !important;
    gap: 10px !important;
  }

  .new-billpowerusedunit {
    display: flex;
    width: 100% !important;
    flex-direction: column;
    gap: 10px;
  }

  .power-amount-input-block,
  .billingInputLabelBlock,
  .power-amount,
  .monthDatePicker,
  .bill-rent-month-input {
    width: 100% !important;
  }

  .bill-rent-month {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .power-amount {
    display: flex;
    align-items: center;
    justify-content: start !important;
    flex-wrap: wrap;
  }
  .selectDropDown,.selectDropDown-viewBill,.table-search {
    font-size: 12px !important;
  }
.selectDropDown-viewBill{
  width: 30% !important;
}
  .new-register-input-block {
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 18px 20px;
    flex-wrap: wrap;
    gap: 20px;
  }
  .--new-register-label-two,
  .newRegisterInputBlockTwo,
  .view-register-title {
    width: 100% !important;
  }
  .register-btn-block {
    width: 100% !important;
    justify-content: center !important;
  }
  .register-submit-btn {
    width: 70% !important;
  }
  .view-bill-search-container,
  .view-room-head-new-btn-block {
    width: 100% !important;
    justify-content: space-between !important;
    padding: 8px !important;
  }

  .register-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    flex-wrap: wrap;
    width: 100% !important;
  }
  .billing-invoice-para {
    font-size: 8px !important;
    font-weight: 600 !important;
  }


  .billing-invoice-stu-block {
    width: 100% !important;
  }
  .billing-invoice-info-block,
  .billing-invoice-acc-block {
    width: 70% !important;
  }
  .invoicePara,
  .invoice_Para {
    font-size: 9px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
  }
  .card-block {
    width: none !important;
  }
}

@media (min-width: 450px) and (max-width: 692px) {
  .billing-logo {
    width: 100px !important;
    height: 100px !important;
  }
  .billing-invoice-para {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .billing-invoice-logo-block,
  .billing-invoice-stu-block {
    width: 100% !important;
  }
  .company_name_shopename{
    font-size:0.8rem;
  }

  .billing-invoice-info-block,
  .billing-invoice-acc-block {
    width: 70% !important;
  }

  .new-register-input-block {
    width: 100%;
    display: flex;
    padding: 18px 20px;
    flex-wrap: wrap !important;
    gap: 20px;
  }

  .register-container {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    padding: 0px 10px;
    flex-wrap: wrap !important;
    width: 100% !important;
  }
  .view-register-title,
  .register-submit-btn {
    width: 50% !important;
  }
}
 
@media (min-width: 450px) and (max-width: 1298px){
  .view-bill-title{
    font-size: 17px !important;
    line-height: normal !important;
  }
} 


.newRoomInputLabel {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}
.newRoomInputField {
  width: 100%;
  padding: 10px 5px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: left;
  border-radius: 5px;
  outline: none;
}
.new-room-para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #4e73de;
  padding: 0px 10px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.datefilter {
  width: 100%;
  max-width: none;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 42px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.daterange-viewbill {
  width: 35%;
}
.datefilter::placeholder {
  font-size: 12px; /* Adjust the font size as needed */
}
.room-container {
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 1px;
  border: 1px solid rgba(30, 30, 121, 0.89);
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}
.room-container:hover {
  background-color: rgba(21, 21, 87, 0.89);
  color: white;
}
.newRoomInputBlockContainer {
  height: 43vh;
  border: 1px dotted black;
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: thin;
  padding: 5px;
  background-color: #f5f5f581;
}

/* SIDEBAR */
.nav-label {
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 21.78px;
  text-align: left;
  width: 100%;
  color: white;
  padding: 0.5rem 1rem !important;
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.sidebar-header {
  /* background-color: #092f57!important; */
  border-radius: 2px;
  /* padding: 1px 0px; */
  margin: 0px;
}
.sidebar-head {
  font-size: 15px;
  font-weight: 800;
  color: white;
  letter-spacing: 2px;
}
.sidebar-image {
  width: 35px;
  height: 35px;
  border-radius: 2px;
}
.nav-label:hover {
  background-color: #0e427a7a;
  color: #ffff;
}
.nav-label.active {
  background-color: #0e427ae5;
  color: #ffff;
}

/* Sidebar styles */
.sidebar {
  width: 16.6%;
  height: 100vh;
  border-radius: 3px;
  /* background: hsl(219deg 46.05% 50.37%); */
  background: hsl(219deg 52.78% 59.98%);
  /* linear-gradient(
    to top,
    hsla(219, 67%, 51%, 0.774) 0%,
    #6991c7 100%
  );
   */

  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease;
  transform: translateX(0); /* Always visible on large screens */
  z-index: 999; /* Ensure sidebar is above other content */
}
.sidebar.open {
  transform: translateX(0); /* Visible when toggled */
}
/* Hamburger icon styles */
.hamburger-icon {
  display: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  position: fixed;
  top: 0px !important;
  left: 0px !important;
  z-index: 1000;
}
.hamburger_icon {
  display: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  position: fixed;
  top: 0px;
  left: 160px;
  z-index: 1000;
}
.new-billpowerusedunit {
  display: flex;
  width: 50%;
  gap: 20px;
}

@media (max-width: 992px) {
  .sidebar {
    width: 200px;
    transform: translateX(-100%); /* Hidden on small screens */
  }
  .sidebar.open {
    transform: translateX(0); /* Visible when toggled on small screens */
  }
  .mainSection {
    height: auto !important;
    overflow: auto !important;
    scrollbar-width: none !important;
  }

  /* Define the animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-50px); /* Adjust as needed */
    }
    to {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  /* Apply the animation to the .hamburger-icon */
  .hamburger-icon {
    display: block;
    top: 0px !important;
    left: 160px !important;
    transition: transform 0.1s ease !important;

    /* Initial state */
    opacity: 0;
    visibility: hidden;

    /* Animation settings */
    animation: fadeIn 0.1s ease-out 1s forwards; /* Adjust duration and delay as needed */
  }

  .hamburger_icon {
    display: flex;
    border-radius: 2px;
    width: 100%;
    position: fixed;
    top: 0px;
    padding: 10px;
    left: 0px;
    background-color: #718fb8;
    z-index: 5;
  }

  .viewBillMainSection,
  .registrationMainContainer,
  .rentBillMainSection {
    position: relative;
    top: 40px;
    height: auto !important;
    padding-bottom: 5px !important;
  }
  .rentBillMainSection,
  .rentalFormBlock,
  .registrationMainContainer {
    height: auto !important;
  }

  .arrow-img-icon {
    width: 25px !important;
    height: 25px !important;
  }
  .new-bill-head-block {
    width: 100% !important;
  }
  .rentBillHeading,
  .view-bill-heading {
    font-size: 16px !important;
    line-height: normal !important;
    font-weight: 500 !important;
  }
  .loginHeading {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }
  .loginSubHeading,
  .login_input_field {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
}
